import React, { useEffect, useState } from "react";
import PaymentService from '../../services/PaymentService';
import JobDataService from '../../services/JobService';
import OrderService from '../../services/OrderService';
import { Button, Spinner } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import uuid from 'react-uuid'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PurchaseForm = ({ job, handleBackStep, customOrder }) => {
    let orderId = null;
    let intialPurchaseOption = "basic";

    if (!customOrder) {
        orderId = uuid();
    } else {
        orderId = customOrder.id;
        intialPurchaseOption = customOrder.plan;
    }

    const [purchaseOption, setPurchaseOption] = useState(intialPurchaseOption);
    const [loading, setLoading] = useState(false)

    const styleWidthButton = {
        width: "190px"
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        JobDataService.createDraftJob({ ...job, "createdAt": new Date()}).then((res) => {
            job.id = res.data.id;
        }).catch((err) => {
            console.log(err);
        });
    }, [])

    const toggleAddFeature = () => {
        if (purchaseOption === "basic") {
            setPurchaseOption("featured");
        } else {
            setPurchaseOption("basic");
        }
    }

    const handleStripeCheckout = async () => {
        setLoading(true);
        let priceId = null;
        let response = null;
        const stripe = await stripePromise;

        if (purchaseOption === "featured") {
            priceId = process.env.REACT_APP_STRIPE_FEATURED_PRICE_ID;
        } else {
            priceId = process.env.REACT_APP_STRIPE_BASIC_PRICE_ID;
        }

        response = await PaymentService.getStripeSessionId({
            priceId: priceId,
            orderId: orderId,
            sessionType: "subscription"
        });

        const session = response.data;

        let orderExpiry = new Date();
        orderExpiry.setMonth(orderExpiry.getMonth() + 1); // 1 month ahead 

        let data = {
            id: orderId,
            customerEmail: job.email,
            jobId: job.id,
            stripeSessionId: session.id,
            expiry: orderExpiry,
            paid: false,
            plan: purchaseOption,
        };

        OrderService.createNewOrderOrUpdate(data).then((res) => {
            // When the customer clicks on the button, redirect them to Checkout.
            stripe.redirectToCheckout({
                sessionId: session.id          
            }).then((result) => {
                if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                }
            });
        }).catch(err => {
            console.log(err);
        });
    }

    const FeaturedTag = {
        background: "#FFD528",
        float: "right"
    }

    return (
        <div className="mb-5 mt-4">
            <div className="px-5">
                {!customOrder && <div className="row">
                    <div className="col">
                        <a
                            className="cursor-pointer text-info ml-2"
                            onClick={handleBackStep}
                        >
                            <span className="nav-link-inner--text">
                                <i className="fa fa-chevron-left mr-2"></i><b>Make Changes</b>
                            </span>
                        </a>
                    </div>
                </div>}

                <div className="border rounded shadow-lg px-4 pt-4 pb-4 mt-4 w-auto">
                    <div className="row">
                        <div className="col">
                            <div className="h2">
                                Order Summary
                            </div>
                            <div className="border px-3 mt-3 rounded">
                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="">
                                            1 x Basic Job Listing
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="text-right">
                                            $99.00
                                        </div>
                                    </div>
                                </div>
                                {purchaseOption == "featured" &&
                                    <div className="row mt-3">
                                        <div className="col">
                                            <div>
                                                + Featured Upgrade <a onClick={toggleAddFeature} href="#">(Remove)</a>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="text-right">
                                                $79.00
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row mt-5">
                                    <div className="col">
                                        Tax
                                        </div>
                                    <div className="col text-right">
                                        $0.00
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col h3">
                                        Total
                                        </div>
                                    <div className="col text-right h3">
                                        {purchaseOption == "basic" &&
                                            <b>USD $99.00</b>
                                        }

                                        {purchaseOption == "featured" &&
                                            <b>USD $178.00</b>
                                        }
                                    </div>
                                </div>
                            </div>
                            {purchaseOption != "featured" &&
                                <div>
                                    <div className="px-2 mt-4">
                                        Addons
                                    </div>
                                    <div onClick={toggleAddFeature} className="cursor-pointer border bg-white grow rounded mt-2 px-3 py-3 item">
                                        <div className="row">
                                            <div className="col-9">
                                                <div>
                                                    <b>+ Featured Badge ($79.00)</b>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-none d-sm-block">
                                                    <div className="pull-right">
                                                        <div style={FeaturedTag} className="text-center py-1 rounded text-xs w-auto px-2">
                                                            <b>Featured</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="mt-4 row">
                                <div className="col-12 ml-2 text-left">
                                    <div className="font-weight-bold">
                                        Terms & Conditions
                                    </div>
                                    <div className="mt-2">
                                        <p>
                                            Your listing will automatically renew every 30 days unless removed. <br /> Listings can be removed at anytime through the <b>receipt emailed after purchase.</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            className="btn-icon border-0"
                            color="info"
                            size="lg"
                            style={styleWidthButton}
                            onClick={handleStripeCheckout}
                        >
                            {loading ?

                                <span className="nav-link-inner--text ml-1">
                                    <Spinner size="sm" color="light" />
                                </span>
                                :
                                <span className="nav-link-inner--text ml-1">
                                    <b>Secure Checkout</b><i className="fa fa-lock ml-2s" />
                                </span>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseForm;