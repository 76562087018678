import http from "../http-common";

const getAll = (params) => {
  return http.get("/jobs", { params });
};

const get = id => {
  return http.get(`/jobs/${id}`);
};

const create = data => {
  return http.post("/jobs", data);
};

const createDraftJob = data => {
  data.active = false;
  data.ranking = 3;
  return http.post("/jobs", data);
};

const update = (id, data) => {
  return http.put(`/jobs/${id}`, data);
};

const remove = id => {
  return http.delete(`/jobs/${id}`);
};

const removeAll = () => {
  return http.delete(`/jobs`);
};

const findByTitle = title => {
  return http.get(`/jobs?title=${title}`);
};

const getSelectOptions = async () => {
  let result = {}
  
  result['locations'] = (await http.get(`/locations`)).data;
  result['categories'] = (await http.get(`/categories`)).data;
  result['companies'] = (await http.get(`/companies`)).data;

  return result;
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  createDraftJob,
  getSelectOptions
};
