import React from "react";

// core components
import CustomNavbar from "../components/navbars/Navbar.js";
import CustomFooter from "../components/footers/Footer.js";
import Order from "../components/Order.js";

class JobOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: props.match.params.id,            
        };
    }

    render() {
        return (
            <div>
                <CustomNavbar />
                <div className="px-sm-4">
                    <Order id={this.state.orderId} />
                </div>
                <CustomFooter />
            </div>
        );
    }
}

export default JobOrder;
