import React from "react";
import ReactGA from 'react-ga';
import store from 'store-js';
import AlertTemplate from 'react-alert-template-basic'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./App.scss";
import "react-toggle/style.css";

import Home from "./pages/Home";
import Search from "./pages/Search";
import JobPage from "./pages/JobPage";
import PostAJob from "./pages/PostAJob";
import JobOrder from "./pages/JobOrder";
import PaymentSuccess from "./pages/PaymentSuccess";
import Billing from "./pages/Billing";
import uuid from 'react-uuid'

const trackingId = "UA-153759131-5";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

window.onbeforeunload = function () {
  store.clearAll();
}

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function App() {
  return (
    <Router>
      <AlertProvider template={AlertTemplate} {...options}>
        <div>
          <div className="container mt-3">
            <Switch>
              <Route exact path={["/", "/jobs"]} component={Home} />
              <Route exact path="/search" component={props => <Search {...props} />} key={uuid()} />
              <Route exact path="/job/:id" component={props => <JobPage {...props} />} key={uuid()} />
              <Route exact path="/jobs/new" component={props => <PostAJob {...props} />} key={uuid()} />
              <Route exact path="/order/:id" component={props => <JobOrder {...props} />} key={uuid()} />
              <Route exact path="/payment/:id" component={props => <PaymentSuccess {...props} />} key={uuid()} />
              <Route exact path="/billing/:id" component={props => <Billing {...props} />} key={uuid()} />
            </Switch>
          </div>
        </div>
      </AlertProvider>
    </Router>
  );
}

export default App;
