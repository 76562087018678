import React from "react";
import { Link } from "react-router-dom";
import logoImg from '../../assets/img/brand/logo1.svg';

// reactstrap components
import {
    NavbarBrand,
    Navbar,
    Container,
} from "reactstrap";

class SimpleCustomNavbar extends React.Component {
    componentDidMount() { }

    render() {
        const logoStyle = {
            width: "140px"
        }

        return (
            <header className="header-global">
                <Navbar
                    className="navbar-main navbar-transparent navbar-light fixed"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                            <img
                                style={logoStyle}
                                alt="..."
                                src={logoImg}
                            />
                        </NavbarBrand>

                    </Container>

                </Navbar>
            </header>
        );
    }
}

export default SimpleCustomNavbar;
