import React, { useState } from "react";

import {
    Button,
} from "reactstrap";


const SimpleSearchBox = () => {
    const [search, setSearch] = useState("")
    const [searchLoader, setSearchLoader] = useState(false)  

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the Enter keycode
            handleSearch({});
        }
    }

    const handleSearch = () => {
        setSearchLoader(true);
        let route = "/search?searchTerm=" + search;
        window.location = window.location.origin + route;
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-sm-7 px-1">
                    <div className="rounded bg-white border shadow-sm h-auto mt-3 pr-2 pl-2 py-2">
                        <div className="input-group">
                            <span className="input-group-prepend ">
                                <div className="input-group-text border-0">
                                    <i className="fa fa-search"></i>
                                </div>
                            </span>
                            <input placeholder="Search job title, company, industry..." onKeyPress={enterPressed} className="form-control text-dark pl-1 pl-sm-2 py-4 border-0 rounded-right border pr-2" onChange={e => setSearch(e.target.value)} type="search" value={search} />
                            <span className="input-group-append ml-3">
                                <Button
                                    className="px-4 rounded w-full"
                                    onClick={handleSearch}
                                    color="info"
                                    type="button">
                                    {searchLoader ?
                                        <span className="spinner-border spinner-border-sm mx-3" role="status" aria-hidden="true"></span>
                                        :
                                        <b>Search</b>
                                    }
                                </Button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimpleSearchBox;