import React, { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'reactstrap';

function Upload({ uploadedFileHandler, loader }) {

    const [rejected, setRejected] = useState(false)

    const onDropAccepted = useCallback(
        (files) => {
            uploadedFileHandler(files[0]);
        },
        [],
    );

    const onDropRejected = useCallback(
        () => {
            setRejected(true);
        }
    )

    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        maxFiles: 1,
        maxSize: 1000000,
        onDropAccepted,
        onDropRejected
    });

    return (
        <div className="px-1">
            <section className="container-upload">
                <div {...getRootProps({ className: 'dropzone' })}>
                    {loader ?
                        <Spinner color="dark" />
                        :
                        <div>
                            {acceptedFiles[0] ?
                                <div>
                                    <p>Uploaded file</p>
                                    <em>
                                        {acceptedFiles[0].path}
                                    </em>
                                </div>
                                :
                                <div>
                                    <input {...getInputProps()} />
                                    <p>Drop your logo here, or click to select files to upload</p>
                                    {rejected ?
                                        <em className="text-red">File must be a jpeg or png and <b>smaller than 1MB.</b></em>
                                        :
                                        <em>(Only *.jpeg and *.png images will be accepted)</em>}
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>
        </div>
    );
}

export default Upload;