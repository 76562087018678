import React from "react";
import OrderService from "../services/OrderService";
import { Tick } from 'react-crude-animated-tick';

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: props.match.params.id,
            customerEmail: null,
            paymentStatus: null,
            jobId: null
        };
    }

    componentDidMount() {
        OrderService.validatePayment({"order_id": this.state.orderId}).then((payment) => {                        
            if (payment.data.status === "paid") {
                console.log(payment)
                this.setState({ jobId: payment.data.jobId, customerEmail: payment.data.email, paymentStatus: "paid" });
            } else {
                this.setState({ paymentStatus: "unpaid" });
            }
        });
    }

    render() {
        if (this.state.paymentStatus === "paid") {
            return (
                <div>
                    <div className="vertical-center-success">
                        <div>
                            <Tick size={200} />
                        </div>
                        <div className="text-center display-4">
                            Purchase Confirmed (Ref #{this.state.jobId})
                        </div>
                        <div className="text-center mt-2">
                            <p>A confirmation email has been sent to <b>{this.state.customerEmail}</b>.</p>
                        </div>
                        <div className="mt-5 text-center">
                            <a href="mailto:support@exithunt.io">Contact Support</a> | <a href={`/order/${this.state.orderId}`}>Job Settings</a>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.paymentStatus === "unpaid") {
            return (
                <div>
                    <div className="vertical-center-success">                        
                        <div className="text-center display-4">
                            Payment Failed. <a href={`/order/${this.state.orderID}`}>Try again.</a>
                        </div>                        
                        <div className="mt-5 text-center">
                            <a href="mailto:support@exithunt.io">Contact Support</a> | <a href="/">Return to Exithunt</a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<></>)
        }
    }
}

export default PaymentSuccess;
