import http from "../http-common";

const getStripeSessionId = (data) => {  
  return http.post(`/create-checkout-session/`, data);
};

const createSubscription = (data) => {  
  return http.post(`/create-subscription/`, data);
};

const unsubscribeCustomer = (data) => {  
  return http.post(`/unsubscribe/`, data);
};

export default {
    getStripeSessionId,
    createSubscription,
    unsubscribeCustomer
};
