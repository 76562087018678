import React, { useState, useEffect } from "react";
import OrderService from "../services/OrderService";
import JobDataService from "../services/JobService";
import OrderSettings from "./OrderSettings";
import OrderEditJob from "./OrderEditJob";
import OrderPayment from "./OrderPayment";

const Order = (data) => {

    const [order, setOrder] = useState(null);
    const [job, setJob] = useState(null);
    const [tab, setTab] = useState(() => "settings");

    const retrieveOrder = (id) => {
        OrderService.get(id)
            .then((response) => {
                const order = response.data;
                setOrder(order);
                retrieveJob(order.jobId);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveJob = (id) => {
        JobDataService.get(id)
            .then((response) => {
                const job = response.data;
                setJob(job);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleTabChange = (tab) => {
        setTab(tab);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        retrieveOrder(data.id);        
    }, [])

    const customBorder = {
        borderRight: "5px solid",
        fontWeight: "bold"
    }

    if (order && job) {
        return (
            <div className="mt-sm-4 mt-2">
                <div className="container">
                    <div className="row mt-4 mb-5">
                        <div className="col-3 display-4">
                            <span>Job Dashboard</span>
                        </div>
                        <div className="col-9 display-4 px-2 text-right">
                            {job.title} - {job.company}
                            {order.paid && order?.plan != "canceled" && order?.plan != "removed" &&
                                <div className="text-sm">
                                    <a href={`/job/${job?.id}`} target="_blank">(View Listing)</a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <div style={tab == "settings" ? customBorder : {}} className="pr-4 pl-2 py-3 sideTab cursor-pointer" onClick={() => { setTab("settings") }}>
                                Settings
                            </div>
                            <div style={tab == "edit" ? customBorder : {}} className="pr-4 pl-2 py-3 sideTab cursor-pointer" onClick={() => { setTab("edit") }}>
                                Edit Job
                            </div>
                            {!order.paid &&
                                <div style={tab == "payment" ? customBorder : {}} className="pr-4 pl-2 py-3 sideTab cursor-pointer" onClick={() => { setTab("payment") }}>
                                    Payment
                                </div>
                            }
                        </div>
                        <div className="col-10 rounded">
                            {tab == "settings" &&
                                <OrderSettings currentOrder={order} currentJob={job} changeTab={handleTabChange} />
                            }
                            {tab == "edit" &&
                                <OrderEditJob currentOrder={order} currentJob={job} />
                            }
                            {tab == "payment" &&
                                <OrderPayment currentOrder={order} currentJob={job} />
                            } 
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        )
    }
}


export default Order;