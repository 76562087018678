import React, { useEffect, useState } from "react";
import Select from 'react-select';
import store from 'store-js';
import JobsDataService from "../services/JobService";

import {
    Button,
} from "reactstrap";

function convertArrayToSelectOptionFormat(rawArray) {
    let result = [];

    if (rawArray instanceof Array) {
        rawArray.forEach(element => {
            result.push({ value: element, label: element });
        });
    } else if (rawArray) {
        result.push({ value: rawArray, label: rawArray });
    }

    return result;
}

function retrieveParamsAndReturnRoute(rawParams) {
    let route = "/search?"

    Object.keys(rawParams).forEach((paramKey) => {
        if (rawParams[paramKey] && rawParams[paramKey] instanceof Array) {
            rawParams[paramKey].forEach((param) => {
                route += paramKey + "=" + param['value'] + "&";
            });
        } else if (rawParams[paramKey]) {
            route += paramKey + "=" + rawParams[paramKey] + "&";
        }
    });

    return route;
}

const SearchBox = (data) => {
    const [toggleFilter, setToggleFilter] = useState(false)
    const [search, setSearch] = useState("")
    const [searchLoader, setSearchLoader] = useState(false)
    const [location, setLocation] = useState(null)
    const [category, setCategory] = useState(null)
    const [company, setCompany] = useState(null)
    const [locationOptionsOpen, setLocationOptionsOpen] = useState((<div><i className="ni ni-pin-3 text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Location</span></div>))
    const [categoryOptionsOpen, setCategoryOptionsOpen] = useState((<div><i className="ni ni-tag text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Category</span></div>))
    const [companyOptionsOpen, setCompanyOptionsOpen] = useState((<div><i className="ni ni-building text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Company</span></div>))
    const [locationOptions, setLocationOptions] = useState(null)
    const [categoryOptions, setCategoryOptions] = useState(null)
    const [companyOptions, setCompanyOptions] = useState(null)

    const handleChangeLocation = (change) => {
        // Check if option was removed from list
        handleSearch({ updatedLocation: change });
    }

    const handleChangeCategory = (change) => {
        // Check if option was removed from list
        handleSearch({ updatedCategory: change });
    }

    const handleChangeCompany = (change) => {
        // Check if option was removed from list
        handleSearch({ updatedCompany: change });
    }

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the Enter keycode
            handleSearch({});
        }
    }

    const handleSearch = ({ updatedLocation = location, updatedCategory = category, updatedCompany = company }) => {
        setSearchLoader(true);
        let route = retrieveParamsAndReturnRoute({
            "location": updatedLocation,
            "category": updatedCategory,
            "company": updatedCompany,
            "searchTerm": search,
            "page": 1
        });

        window.location = window.location.origin + route;
    }

    const retrieveSelectOptions = () => {
        const selectOptions = store.get('selectOptions');

        if (selectOptions) {
            setSelectedOptions(selectOptions);
        } else {
            JobsDataService.getSelectOptions()
                .then((response) => {
                    store.set('selectOptions', response)
                    setSelectedOptions(response);
                })
        }
    }

    const setPreselectedOptions = () => {
        setSearch(data.jobSearchTerm);
        setLocation(convertArrayToSelectOptionFormat(data.jobLocation));
        setCategory(convertArrayToSelectOptionFormat(data.jobCategory));
        setCompany(convertArrayToSelectOptionFormat(data.jobCompany));
    }

    const setSelectedOptions = (data) => {
        setLocationOptions(convertArrayToSelectOptionFormat(data['locations']));
        setCategoryOptions(convertArrayToSelectOptionFormat(data['categories']));
        setCompanyOptions(convertArrayToSelectOptionFormat(data['companies']));
    }

    useEffect(() => {
        retrieveSelectOptions();
        setPreselectedOptions();

        const { innerWidth: width } = window;

        if (width > 600) {
            setToggleFilter(true);
        }
    }, []);

    const customSelectStyles = {
        control: base => ({
            ...base,
            height: 60,
            minHeight: 60
        })
    };

    return (
        <div className="fancy">
            <customBox>
                <div className="custom-search-box-outline px-sm-5 py-sm-5 mt-5">
                    <div className="row">
                        <div className="col">
                            <div className="rounded bg-white border shadow-sm w-full h-auto mt-sm-2 pr-2 pl-2 py-2">
                                <div className="input-group">
                                    <span className="input-group-prepend ">
                                        <div className="input-group-text border-0">
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </span>
                                    <input placeholder="Search job title, company, industry" onKeyPress={enterPressed} className="form-control text-dark pl-2 py-4 border-0 rounded-right pr-2" onChange={e => setSearch(e.target.value)} type="search" value={search} />
                                    <span className="input-group-append ml-3 d-none d-sm-inline">
                                        <Button
                                            className="px-4 rounded w-full"
                                            onClick={handleSearch}
                                            color="info"
                                            type="button">
                                            {searchLoader ?
                                                <span className="spinner-border spinner-border-sm mx-3" role="status" aria-hidden="true"></span>
                                                :
                                                <b>Search</b>
                                            }
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {toggleFilter &&
                        <div className="row">
                            <div className="col">
                                <div className="py-2 mt-2">
                                    <div className="row">
                                        <div className="col-sm-4 mt-3 mt-sm-0">
                                            <div>
                                                <Select
                                                    placeholder={locationOptionsOpen}
                                                    value={location}
                                                    onFocus={() => { setLocationOptionsOpen((<div>Search for location...</div>)) }}
                                                    onBlur={() => { setLocationOptionsOpen((<div><i className="ni ni-pin-3 text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Location</span></div>)) }}
                                                    onChange={handleChangeLocation}
                                                    options={locationOptions}
                                                    className="text-sm shadow-sm"
                                                    isMulti
                                                    pageSize={6}
                                                    styles={customSelectStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#FFFFFF',
                                                            primary: '#ADB5BD',
                                                            neutral20: '#F2F2F2'
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mt-3 mt-sm-0">
                                            <div >
                                                <Select
                                                    placeholder={categoryOptionsOpen}
                                                    onFocus={() => { setCategoryOptionsOpen((<div>Search for category...</div>)) }}
                                                    onBlur={() => { setCategoryOptionsOpen((<div><i className="ni ni-tag text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Category</span></div>)) }}
                                                    value={category}
                                                    onChange={handleChangeCategory}
                                                    options={categoryOptions}
                                                    className="text-sm shadow-sm"
                                                    isMulti
                                                    pageSize={6}
                                                    styles={customSelectStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#FFFFFF',
                                                            primary: '#ADB5BD',
                                                            neutral20: '#F2F2F2'
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4 mt-3 mt-sm-0">
                                            <div>
                                                <Select
                                                    placeholder={companyOptionsOpen}
                                                    onFocus={() => { setCompanyOptionsOpen((<div>Search for company...</div>)) }}
                                                    onBlur={() => { setCompanyOptionsOpen((<div><i className="ni ni-building text-info vertical-align-middle text-lg ml-2"></i> <span className="ml-2">Company</span></div>)) }}
                                                    value={company}
                                                    onChange={handleChangeCompany}
                                                    options={companyOptions}
                                                    className="text-sm shadow-sm"
                                                    isMulti
                                                    pageSize={6}
                                                    styles={customSelectStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#FFFFFF',
                                                            primary: '#ADB5BD',
                                                            neutral20: '#F2F2F2'
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    <div className="d-flex mt-3 d-sm-none">
                        <div className="">
                            <Button
                                className="px-4 rounded"
                                onClick={() => { setToggleFilter(!toggleFilter) }}
                                color="info"
                                type="button">
                                <i className="fa fa-filter"></i>
                            </Button>
                        </div>
                        <div className="col-10 pl-0 ml-2">
                            <Button
                                className="px-4 rounded btn-block d-sm-none"
                                onClick={handleSearch}
                                color="info"
                                type="button">
                                {searchLoader ?
                                    <span className="spinner-border spinner-border-sm mx-3" role="status" aria-hidden="true"></span>
                                    :
                                    <b>Search</b>
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </customBox>
        </div>
    );
}

export default SearchBox;