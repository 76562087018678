import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import JobsDataService from "../services/JobService";
import JobListing from "./JobListing";

const MAILTO_EMAIL_REGEX = /^mailto\:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Job = (data) => {

  const [job, setJob] = useState(null);
  
  const history = useHistory();

  const retrieveJob = (id) => {

    JobsDataService.get(id)
      .then((response) => {
        const job = response.data;
        setJob(job);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveJob(data.id);
  }, [])

  return (
    <div className="mt-sm-4 mt-2">
        <div className="container">
          <div className="row align-items-center d-flex justify-content-between px-3 px-sm-0">
            <div className="flex px-sm-4">
              <a
                className="cursor-pointer text-info"
                onClick={() => { history.goBack() }}
                target="_blank"
              >
                <span className="nav-link-inner--text">
                  <i className="fa fa-chevron-left mr-2"></i><b>Back</b>
                </span>
              </a>
            </div>
            <div className="flex  text-right d-sm-none">
              <Button
                className="btn-icon"
                color="info"
                size="md"
                href={job?.linkToApplyURL}
                target="_blank"
              >
                <span>
                  <b>{MAILTO_EMAIL_REGEX.test(job?.linkToApplyURL) ? "Email Resume" : "Apply For This Position"}</b>
                </span>
              </Button>
            </div>
          </ div>
        </div>
        <JobListing job={job} linksNotClickable={false}/>
    </div>
  );
}


export default Job