import React, { useState, useEffect } from "react";
import JobInfoEditForm from "./forms/JobInfoEditForm";
import JobDataService from "../services/JobService";
import OrderService from "../services/OrderService";
import { useAlert } from 'react-alert'

const OrderEditJob = ({ currentJob, currentOrder }) => {
    
    const alert = useAlert();

    const [order, setOrder] = useState(null);
    const [job, setJob] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setOrder(currentOrder);
        setJob(currentJob);
    }, [])

    const handleUpdatedJob = (updatedJob) => {
        if (updatedJob.email != order.customerEmail) {
            let updatedOrder = order;
            updatedOrder.customerEmail = updatedJob.email;
            OrderService.update(updatedOrder).then(() => {
                alert.success('Successfully updated!'); 
            });
        } else {
            JobDataService.update(updatedJob.id, updatedJob).then(() => {
                alert.success('Successfully updated!'); 
            });
        }
    }

    if (job && order) {
        return (
            <div>
                <JobInfoEditForm job={{ ...job, "email": order.customerEmail }} updateJob={handleUpdatedJob} />
            </div>
        );
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default OrderEditJob;
