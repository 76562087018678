import React, { useState, useEffect } from "react";
import { Progress } from 'reactstrap';

import JobInfoForm from './forms/JobInfoForm';
import AddJobPreview from './AddJobPreview';
import PurchaseForm from './forms/PurchaseForm';

const AddJobSteps = () => {

    const [step, setStep] = useState(1);
    const [job, setJob] = useState(null);

    const handleJobInfo = (job) => {        
        setJob(job);
        setStep(step+1);    
    }

    useEffect(() => {
    }, [])

    return (
        <div className="w-full">
            <div className="row justify-content-center text-center mt-5">
                <div className="col-4">
                    {step >= 1 ?
                        <div>
                            <h4 className="text-info">
                                Step 1
                            </h4>
                            <h2 className="text-info">
                                Job Info
                            </h2>
                        </div>
                        :
                        <div>
                            <h4 className="text-info">
                                Step 1
                            </h4>
                            <h2 className="text-info">
                                Job Info
                            </h2>
                        </div>
                    }
                </div>
                <div className="col-3">
                    {step >= 2 ?
                        <div>
                            <h4 className="text-info">
                                Step 2
                            </h4>
                            <h2 className="text-info">
                                Preview Job
                            </h2>
                        </div>
                        :
                        <div>
                            <h4 className="text-gray">
                                Step 2
                            </h4>
                            <h2 className="text-gray">
                                Preview Job
                            </h2>
                        </div>
                    }
                </div>
                <div className="col-4">
                    {step >= 3 ?
                        <div>
                            <h4 className="text-info">
                                Step 3
                            </h4>
                            <h2 className="text-info">
                                Purchase <span className="hover:underline">(starting at $99)</span>
                            </h2>
                        </div>
                        :
                        <div>
                            <h4 className="text-gray">
                                Step 3
                            </h4>
                            <h2 className="text-gray">
                                Purchase <span className="hover:underline">(starting at $99)</span>
                            </h2>
                        </div>
                    }
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-11">
                    <div className="progress-wrapper">
                        <Progress max="100" value={step * 33.33} color="info" />
                    </div>
                </div>
            </div>
            {step === 1 &&
                <JobInfoForm job={job} handleNextStep={handleJobInfo} />
            }

            {step === 2 &&
                <AddJobPreview job={job} handleBackStep={() => {setStep(step-1)}} handleNextStep={() => {setStep(step+1)}} />
            }

            {step === 3 &&
                <PurchaseForm job={job} handleBackStep={() => {setStep(step-1)}} />
            }
        </div>
    );
}

export default AddJobSteps;