import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import JobsDataService from "../services/JobService";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AdminSettings from "./AdminSettings";

// reactstrap components
import {
  Media,
  Button
} from "reactstrap";

const godMode = false;

const SimpleJobTable = (data) => {
  const [jobs, setJobs] = useState([])
  const [totalItems, setTotalItems] = useState(null);

  const retrieveJobs = () => {
    let params = {};

    params['size'] = data.jobsPerPage;
    params['active'] = "True";

    if (data?.jobsCategory) {
      params['category'] = data?.jobsCategory;
    }

    JobsDataService.getAll(params)
      .then((response) => {
        const { jobs, totalItems } = response.data;
        setJobs(jobs);
        setTotalItems(totalItems);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const determineDaysPassed = (job) => {
    const jobPostedDate = new Date(job["createdAt"]);
    const today = new Date();

    const numberOfDays = Math.ceil((today - jobPostedDate) / 8.64e7);

    if (numberOfDays == 1) {
      return "Posted today"
    } else {
      return "Posted " + numberOfDays + " days ago"
    }
  }

  useEffect(() => {
    retrieveJobs();
  }, []);

  const FeaturedTag = {
    background: "#FFD528",
    float: "right"
  }

  const FeaturedTagColor = {
    background: "#FFD528",
  }

  const NewTag = {
    float: "right"
  }

  if (totalItems == 0) {
    return (
      <></>
    )
  }

  if (jobs.length > 0) {
    return (
      <div responsive className="px-sm-4 rounded">
        <div className="row mt-5">
          <div className="col mx-auto">
            <div className="row justify-content-between px-1">
              <div className="col-6 text-left">
                <div className="text-lg d-none d-sm-inline text-gray-500">
                  <b>{data?.title}</b>
                </div>
                <div className="text-md d-sm-none text-gray-500">
                  <b>{data?.title}</b>
                </div>
              </div>
              <div className="col-6 text-right">
                <span className="ml-2 text-sm text-gray-400 mt-2"> {totalItems} new openings</span>
              </div>
            </div>
            <div className="mt-3">
              {jobs.map((job, index) => (
                <Link to={`/job/${job.id}`} className="link">

                  <div id={index} className="cursor-pointer border bg-white grow rounded shadow-lg mb-3 item">
                    <div className="py-3 px-3">
                      <div className="row justify-content-between">
                        <div className="col-sm-10 col-12 mt-1 mt-sm-3">
                          <Media>
                            {job['logoURL'] &&
                              <img
                                alt="..."
                                className="logo-square border-black border mr-2"
                                src={job['logoURL']}
                              />
                            }
                            <div>
                              <div className="mb-1 d-sm-none font-weight-bold text-sm font-bold cut-text">
                                {job['title']}
                              </div>
                              <div className="d-none d-sm-block font-weight-bold text-md font-bold">
                                {job['title']}
                                <AdminSettings enabled={godMode} job={job} />
                              </div>
                              <div className="text-sm text-gray-500 d-sm-none cut-text-sm">
                                {job['company']} &middot; {job['location']}
                              </div>
                              <div className="text-sm text-gray-500 d-none d-sm-inline">
                                <span className="mr-1">{job['company']}</span> &middot; <span className="ml-1 mr-1">{job['location']}</span> &middot; <span className="ml-1"> {job['type']} </span>
                              </div>
                            </div>
                          </Media>
                        </div>
                        <div className="d-none d-sm-block col-sm-2 col-0">
                          <div className="pull-right">
                            {job['ranking'] === 1 ?
                              <div style={FeaturedTag} className="text-center py-1 rounded text-xs w-auto px-2">
                                <b>Featured</b>
                              </div>
                              :
                              <>
                                {determineDaysPassed(job) === "Posted today" &&
                                  <div style={NewTag} className="text-center py-1 rounded text-xs w-10">
                                    <b>New</b>
                                  </div>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-sm-12 d-none d-sm-block text-xs text-right">
                          {determineDaysPassed(job)}
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      {job['ranking'] === 1 &&
                        <div style={FeaturedTagColor} className="text-center d-sm-none py-1 rounded-bottom text-xs w-auto px-2">
                          <b>Featured</b>
                        </div>
                      }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <Button
            className="btn-icon"
            color="white"
            size="md"
            href={data.jobsCategory ? `/search?category=${data?.jobsCategory}&pageTitle=${data?.title}` : `/search?`}
          >
            <span className="nav-link-inner-text">
              View More
            </span>
          </Button>
        </div>
      </div >
    );
  } else {
    return (
      <div className="row justify-content-center">
        <div className="col-12 text-center px-5">
          <SkeletonTheme color="#FFFFFF" highlightColor="#F2F2F2">
            <div className="row mt-10 mb-3 justify-content-between">
              <div className="col-2 text-center">
                <Skeleton height={35} />
              </div>

              <div className="col-2 mt-3 text-right">
                <Skeleton height={20} width={100} />
              </div>
            </div>
            <Skeleton className="border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <div className="row mt-4 mb-5 justify-content-center">
              <div className="col-2 text-center">
                <Skeleton height={45} />
              </div>
            </div>

          </SkeletonTheme>

        </div>
      </div>
    );
  }
}

export default SimpleJobTable