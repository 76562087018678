import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AdminSettings from "./AdminSettings";
import { Button } from "reactstrap";

const MAILTO_EMAIL_REGEX = /^mailto\:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const godMode = process.env.REACT_APP_ADMIN == "True" ? true : false;

function JobListing({ job, linksNotClickable }) {

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    }

    const customButtonWidth = {
        width: "230px"
    }

    return (
        <SkeletonTheme color="#FFFFFF" highlightColor="#F2F2F2">
            <div className="container">
                <div className="row justify-content-center mt-4 px-3 px-sm-1">
                    <div className="col-12 col-sm-8 rounded shadow-lg border bg-white py-4 px-4">
                        <div>
                            Job Details
              </div>
                        <div className="mt-2 h2 d-sm-none">
                            {job?.title || <Skeleton className="border rounded" height={50} />}
                        </div>
                        <div className="mt-2 h1 d-sm-inline d-none">
                            {job?.title || <Skeleton className="border rounded" height={50} />}
                            {job && <AdminSettings enabled={godMode} job={job} />}
                        </div>
                        <div className="mt-sm-4 mt-4 text-sm">
                            {job ?
                                <div dangerouslySetInnerHTML={{ __html: job?.description }} />
                                :
                                <Skeleton className="border rounded" height={700}></Skeleton>
                            }
                        </div>
                    </div>
                    <div className="col-12 px-sm-0 px-3 col-sm-3 ml-sm-5 mt-sm-0 mt-4">
                        <div className="row justify-center border shadow-lg rounded bg-white">
                            <div className="col mt-10 mb-4 text-center">
                                <div>
                                    <a onClick={e => e.preventDefault()}>
                                        {job?.logoURL &&
                                            <img
                                                alt="..."
                                                className="logo-square-lg border-black border mx-auto"
                                                src={job?.logoURL}
                                            />
                                        }
                                    </a>
                                </div>
                                <div className="px-2 h3 mt-3">
                                    <b>{job?.company || <Skeleton className="border rounded" height={50} />}</b>
                                </div>
                                <div className="cursor-pointer mt-3" onClick={() => { window.open("/search?company=" + job?.company, "_blank") }}>
                                    Other Open Positions
                                </div>
                                <hr></hr>
                                <div className="mt-2 text-left px-3">
                                    <div>
                                        <h4>Job Type</h4>
                                        <p>{job?.type || <Skeleton className="border rounded" height={30} />}</p>
                                    </div>
                                    <div>
                                        <h4>Location</h4>
                                        <p>{job?.location || <Skeleton className="border rounded" height={30} />}</p>
                                    </div>
                                    <div>
                                        <h4>Date Posted</h4>
                                        <p>{new Date(job?.createdAt).toLocaleDateString()}</p>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <Button
                                        className="btn-icon"
                                        color="info"
                                        size="lg"
                                        disabled={linksNotClickable}
                                        style={customButtonWidth}
                                        href={job?.linkToApplyURL}
                                        target="_blank"
                                    >
                                        <span>
                                            <b>{MAILTO_EMAIL_REGEX.test(job?.linkToApplyURL) ? "Email Resume" : "Apply For This Position"}</b>
                                        </span>
                                    </Button>
                                </div>
                                <div className="mt-3 w-full">
                                    <Button
                                        className="btn-icon"
                                        color="white"
                                        size="lg"
                                        style={customButtonWidth}
                                        disabled={linksNotClickable}
                                        target="_blank"
                                        onClick={handleCopy}
                                    >
                                        {!copied ?
                                            <span className="nav-link-inner--text">
                                                <b>Copy Job Link</b><i className="fa fa-link" />
                                            </span>
                                            :
                                            <span className="nav-link-inner--text">
                                                <b>Copied to Clipboard</b>
                                            </span>
                                        }

                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default JobListing;