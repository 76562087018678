
import React from "react";
import JobsDataService from "../services/JobService";

import {
    Button
} from "reactstrap";

const AdminSettings = (props) => {

    const unfeatureButtonClicked = (e) => {
        e.preventDefault();
        JobsDataService.update(props.job.id, {"ranking": 3});
        window.location.reload();
    }
    
    const featureButtonClicked = (e) => {
        e.preventDefault();
        JobsDataService.update(props.job.id, {"ranking": 1});
        window.location.reload();
    }

    const deleteButtonClicked = (e) => {
        e.preventDefault();
        JobsDataService.remove(props.job.id);
        window.location.reload();
    }

    const deactivateButtonClicked = (e) => {
        e.preventDefault();
        JobsDataService.update(props.job.id, {"active": false});
        window.location.reload();
    }

    if (props?.enabled) {
        return (
            <>
                <span className="ml-2">
                    {props.job.ranking == 1 ?
                        <Button
                            className="ml-2"
                            onClick={unfeatureButtonClicked}
                            color="info"
                            size="sm"
                            type="button">
                            <b>Unfeature</b>
                        </Button>
                        :
                        <Button
                            className="ml-2"
                            onClick={featureButtonClicked}
                            color="success"
                            size="sm"
                            type="button">
                            <b>Feature</b>
                        </Button>
                    }
                </span>
                <span>
                    <Button
                        className="ml-2"
                        onClick={deactivateButtonClicked}
                        color="white"
                        size="sm"
                        type="button">
                        <b>Deactivate</b>
                    </Button>
                </span>
                <span>
                    <Button
                        className="ml-2"
                        onClick={deleteButtonClicked}
                        color="danger"
                        size="sm"
                        type="button">
                        <b>Delete</b>
                    </Button>
                </span>
            </>
        );
    } else {
        return <></>;
    }

}

export default AdminSettings;