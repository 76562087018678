import React from "react";

import SimpleJobTable from "./SimpleJobTable.js";

const HomePageJobs = () => {

  return (
    <div>
      <SimpleJobTable jobsPerPage={5} title={'Latest Jobs'}/>
      <SimpleJobTable jobsPerPage={5} jobsCategory="Strategy and Operations" title={'Strategy/Operations'}/>      
      <SimpleJobTable jobsPerPage={5} jobsCategory="Product" title={'Product Jobs'}/>      
      <SimpleJobTable jobsPerPage={5} jobsCategory="Venture Capital" title={'Venture Capital Jobs'}/>
    </div>
  );
}

export default HomePageJobs