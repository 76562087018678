import React from "react";

const queryString = require('query-string');

// core components
import CustomNavbar from "../components/navbars/Navbar.js";
import CustomFooter from "../components/footers/Footer.js";
import JobTable from "../components/JobTable.js";
import SearchBox from "../components/SearchBox.js";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: queryString.parse(this.props.location.search).searchTerm,
            category: queryString.parse(this.props.location.search).category,
            location: queryString.parse(this.props.location.search).location,
            company: queryString.parse(this.props.location.search).company,
            page: queryString.parse(this.props.location.search).page || 1,
            pageTitle: queryString.parse(this.props.location.search).pageTitle,
            jobsPerPage: 10
        };
    }

    render() {
        return (
            <div>
                <CustomNavbar />
                <div className="mt-4 mt-sm-0">
                    <a href="/search">
                        <div className="display-4 d-none d-sm-block text-theme text-center mt-5 mt-sm-2 cursor-pointer text-decoration-none" >
                            <div className="mt-4">
                                {this.state.pageTitle || <div className="text-center px-5 display-4">{"Advanced Search"}</div>}
                            </div>
                        </div>

                        <div className="display-4 d-sm-none text-theme text-left px-3 cursor-pointer text-decoration-none" >
                            {this.state.pageTitle || <div className="mt-5 mb-3">{"Advanced Search"}</div>}
                        </div>
                    </a>
                </div>
                <div className="px-sm-4 px-3">
                    <SearchBox
                        jobSearchTerm={this.state.searchTerm}
                        jobLocation={this.state.location}
                        jobCategory={this.state.category}
                        jobCompany={this.state.company} />
                    <JobTable
                        pageNumber={this.state.page}
                        jobSearchTerm={this.state.searchTerm}
                        jobsPerPage={this.state.jobsPerPage}
                        jobLocation={this.state.location}
                        jobCategory={this.state.category}
                        jobCompany={this.state.company}
                        pageTitle={this.state.pageTitle} />
                </div>
                <CustomFooter />
            </div>
        );
    }
}

export default Search;
