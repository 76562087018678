import React from "react";

import OrderService from '../services/OrderService';

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: props.match.params.id
        };
    }

    componentDidMount() {
        OrderService.getBillingRedirect(this.state.orderId).then((res) => {
            window.location.href = res.data.url;
        });
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default Billing;
