import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from 'reactstrap';
import JobService from "../services/JobService";
import OrderService from '../services/OrderService';
import PaymentService from '../services/PaymentService';

const OrderSettings = ({ currentJob, currentOrder, changeTab }) => {

    const [order, setOrder] = useState(null);
    const [job, setJob] = useState(null);
    const [toggleRemoveModal, setToggleRemoveModal] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setOrder(currentOrder);
        setJob(currentJob);
    }, [])

    const removeListing = () => {
        setRemoveLoading(true);
        let orderToUpdate = order;
        orderToUpdate.plan = 'removed';

        OrderService.update(orderToUpdate).then((res) => {
            PaymentService.unsubscribeCustomer({ "customerId": order.stripeCustomerId }).then((res) => {
                JobService.update(job.id, {"active": false}).then((res) => {
                    window.location.reload();
                }).catch(error => {
                    console.log(error)
                });
            });
        })
    }

    const removeButtonWidth = {
        width: "150px"
    }

    return (
        <div className="border px-4 pb-4 pt-4 rounded shadow-lg">
            <Modal
                className="modal-dialog-centered"
                isOpen={toggleRemoveModal}
                toggle={() => setToggleRemoveModal(!toggleRemoveModal)}
            >
                <div className="modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">
                        Remove Job Listing
                    </h4>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setToggleRemoveModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="px-4">Removing your job will delist it from Exithunt.io. It will be archived and you will still be able to access it. </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setToggleRemoveModal(false)}
                    >
                        Keep Listing
                    </Button>
                    <Button color="danger" style={removeButtonWidth} onClick={removeListing} type="button">
                        {!removeLoading ? `Remove Listing` : <Spinner size="sm" color="light" />}
                    </Button>
                </div>
            </Modal>
            <div className="display-4">
                Your Job Settings
            </div>
            <div className="row pb-2">
                <div className="col-6" >
                    <div className="h3 mt-4">
                        Primary Contact Email
                    </div>
                    {order?.customerEmail} <a href="#" onClick={() => { changeTab("edit") }}>(Update)</a>
                    {order?.paid == true && order?.plan != "canceled" && order?.plan != "removed" &&
                        <div>
                            <div className="h3 mt-5">
                                Listing Type
                            </div>
                            <div className="text-capitalize">
                                {order?.plan} {order?.plan == "basic" && <a href={`/billing/${order?.id}`} >(Upgrade to Featured)</a>}
                            </div>
                            <div className="h3 mt-5">
                                Subscription
                            </div>
                            <Button
                                className="btn-icon border-0 mt-2 px-4"
                                color="info"
                                size="lg"
                                href={`/billing/${order?.id}`}
                            >
                                <span className="nav-link-inner--text">
                                    <b>Billing Dashboard</b>
                                </span>
                            </Button>
                            <div className="text-sm text-gray mt-2 ml-2">
                                View invoices and manage payment.
                            </div>
                        </div>
                    }
                    {order?.plan == "canceled" &&
                        <div>
                            <div className="h3 mt-5">
                                Listing Expires On
                            </div>
                            <div className="text-capitalize">
                                {new Date(order?.expiry).toLocaleDateString()} <a href={`/billing/${order?.id}`} >(Renew)</a>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-6" >
                    {order?.paid == true && order?.plan != "removed" ?
                        <div>
                            <div className="h3 mt-4">
                                Listing Status
                            </div>
                            <div>
                                <svg width='15' height='12'>
                                    <circle cx='5' cy='5' r='2' stroke='green' strokeWidth='4' fill='green' />
                                </svg>
                                Live <a href="#" onClick={() => { setToggleRemoveModal(true) }}>(Remove Job)</a>
                            </div>
                            {order?.plan != "canceled" &&
                                <div>
                                    <div className="h3 mt-5">
                                        Listing Renews On
                                    </div>
                                    <div className="text-capitalize">
                                        {new Date(order?.expiry).toLocaleDateString()} <a href={`/billing/${order?.id}`} >(Cancel)</a>
                                    </div>
                                    <div className="text-sm text-gray mt-2">
                                        Cancelling your subscription will not remove your job.
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <div className="h3 mt-4">
                                Job Status
                            </div>
                            <svg width='15' height='12'>
                                <circle cx='5' cy='5' r='2' stroke='red' strokeWidth='4' fill='red' />
                            </svg>
                            {order?.plan == "removed" ?
                                <>
                                    Removed <a href={`/billing/${order?.id}`}>(Re-open)</a>
                                </>
                                :
                                <>Disabled - Awaiting Payment <a href="#" onClick={() => { changeTab("payment") }}>(Pay Now)</a></>
                            }
                        </div>
                    }
                </div>
            </div>
        </div >

    );
}


export default OrderSettings;