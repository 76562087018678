import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import JobsDataService from "../services/JobService";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AdminSettings from "./AdminSettings";
import Pagination from "react-js-pagination";

// reactstrap components
import {
  Media,
} from "reactstrap";

const godMode = process.env.REACT_APP_ADMIN == "True" ? true : false;

function retrieveParamsAndReturnRoute(rawParams) {
  let route = "/search?"

  Object.keys(rawParams).forEach((paramKey) => {
    if (rawParams[paramKey] && rawParams[paramKey] instanceof Array) {
      rawParams[paramKey].forEach((param) => {
        route += paramKey + "=" + param['value'] + "&";
      });
    } else if (rawParams[paramKey]) {
      route += paramKey + "=" + rawParams[paramKey] + "&";
    }
  });

  return route;
}

const JobTable = (data) => {

  const [jobs, setJobs] = useState([])
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [searchTitle, setSearchTitle] = useState("Latest Jobs");

  const retrieveJobs = (pageNumber) => {
    let params = retrieveParams({
      "location": data?.jobLocation,
      "category": data?.jobCategory,
      "company": data?.jobCompany,
      "searchTerm": data?.jobSearchTerm,
    });

    params['size'] = data.jobsPerPage;
    params['page'] = pageNumber - 1;
    params['active'] = "True";

    JobsDataService.getAll(params)
      .then((response) => {
        const { jobs, totalItems, totalPages } = response.data;
        setJobs(jobs);
        setTotalItems(totalItems);
        setTotalPages(totalPages);
        evaluateParamsAndSetSearchTitle();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveParams = (rawParams) => {
    let params = {};

    Object.keys(rawParams).forEach((paramKey) => {
      if (rawParams[paramKey] && rawParams[paramKey] instanceof Array) {
        let paramArray = [];
        rawParams[paramKey].forEach((param) => {
          paramArray.push(param);
        })
        params[paramKey] = paramArray;
      } else if (rawParams[paramKey]) {
        params[paramKey] = rawParams[paramKey];
      }
    });

    return params;
  }

  const evaluateParamsAndSetSearchTitle = () => {

    if (data?.pageTitle) {
      setSearchTitle("");
      return;
    }

    if (data.jobSearchTerm) {
      setSearchTitle("\"" + data.jobSearchTerm + "\" jobs");
      return;
    }

    if (data.jobCategory) {
      if (data.jobCategory instanceof Array) {
        let title = ""
        data.jobCategory.forEach((category) => {
          title += category + " + "
        })

        //remove last "+"
        setSearchTitle(title.slice(0, -2) + "jobs");
      } else {
        setSearchTitle(data.jobCategory + " jobs");
      }

      return;
    }
  }

  const determineDaysPassed = (job) => {
    const jobPostedDate = new Date(job["createdAt"]);
    const today = new Date();

    const numberOfDays = Math.ceil((today - jobPostedDate) / 8.64e7);

    if (numberOfDays == 1) {
      return "Posted today"
    } else {
      return "Posted " + numberOfDays + " days ago"
    }
  }

  const handlePageChange = (value) => {
    setPage(value);
    if (value) {
      let route = retrieveParamsAndReturnRoute({
        "location": data?.jobLocation,
        "category": data?.jobCategory,
        "company": data?.jobCompany,
        "searchTerm": data?.jobSearchTerm,
        "page": value
      });

      window.location = window.location.origin + route;
    }
  };

  useEffect(() => {
    setPage(data.pageNumber);
    retrieveJobs(data.pageNumber);
  }, []);

  const FeaturedTag = {
    background: "#FFD528",
    float: "right"
  }

  const FeaturedTagColor = {
    background: "#FFD528",
  }

  const NewTag = {
    float: "right"
  }

  if (totalItems == 0) {
    return (
      <div className="mt-10">
        <div className="h2">
          There aren’t any jobs that match your search.
        </div>
        Try narrowing down the filters (e.g Category or Company) or changing up the keywords you're searching for.
      </div>
    );
  }

  if (jobs.length != 0 && page) {
    return (
      <div responsive>
        <div className="row mt-5">
          <div className="col mx-auto">
            <div className="row justify-content-between px-1">
              <div className="col text-left">
                <div className="text-lg d-none d-sm-inline text-gray-500">
                  <b>{searchTitle}</b>
                </div>
                <div className="text-sm d-sm-none text-gray-500 text-right">
                  <div className="text-left h3">
                    <b>{searchTitle}</b>
                  </div>
                  <div className="mt-2">
                    <span className="text-sm d-sm-none text-gray-400"> {totalItems} new openings</span>
                  </div>
                </div>
              </div>
              <div className="col text-right d-none d-sm-inline">
                <span className="ml-2 text-sm text-gray-400 mt-2"> {totalItems} new openings</span>
              </div>
            </div>
            <div className="mt-3">
              {jobs.map((job, index) => (
                <Link to={`/job/${job.id}`} className="link">
                  <div id={index} className="cursor-pointer shadow-lg border bg-white grow rounded mb-3 item">
                    <div className="py-3 px-3">
                      <div className="row justify-content-between">
                        <div className="col-sm-10 col-12 mt-1 mt-sm-3">
                          <Media>
                            {job['logoURL'] &&
                              <img
                                alt="..."
                                className="logo-square border-black border mr-2"
                                src={job['logoURL']}
                              />
                            }
                            <div>
                              <div className="mb-1 d-sm-none font-weight-bold text-sm font-bold cut-text">
                                {job['title']}
                              </div>
                              <div className="d-none d-sm-block font-weight-bold text-md font-bold">
                                {job['title']}
                                <AdminSettings enabled={godMode} job={job} />
                              </div>
                              <div className="text-sm text-gray-500 d-sm-none cut-text-sm">
                                {job['company']} &middot; {job['location']}
                              </div>
                              <div className="text-sm text-gray-500 d-none d-sm-inline">
                                <span className="mr-1">{job['company']}</span> &middot; <span className="ml-1 mr-1">{job['location']}</span> &middot; <span className="ml-1"> {job['type']} </span>
                              </div>
                            </div>
                          </Media>
                        </div>
                        <div className="d-none d-sm-block col-sm-2 col-0">
                          <div className="pull-right">
                            {job['ranking'] === 1 ?
                              <div style={FeaturedTag} className="text-center py-1 rounded text-xs w-auto px-2">
                                <b>Featured</b>
                              </div>
                              :
                              <>
                                {determineDaysPassed(job) === "Posted today" &&
                                  <div style={NewTag} className="text-center py-1 rounded text-xs w-10">
                                    <b>New</b>
                                  </div>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-sm-12 d-none d-sm-block text-xs text-right">
                          {determineDaysPassed(job)}
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      {job['ranking'] === 1 &&
                        <div style={FeaturedTagColor} className="text-center d-sm-none py-1 rounded-bottom text-xs w-auto px-2">
                          <b>Featured</b>
                        </div>
                      }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <Pagination
                activePage={parseInt(page)}
                itemsCountPerPage={1}
                totalItemsCount={totalPages}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                hideFirstLastPages={true}
              />
            </div>
          </div>
        </div>
      </div >
    );
  } else {
    return (
      <div className="row justify-content-center">
        <div className="col-12 text-center px-3">
          <SkeletonTheme color="#FFFFFF" highlightColor="#F2F2F2">
            <div className="row mt-10 mb-3 justify-content-between">
              <div className="col-2 text-center">
                <Skeleton height={35} />
              </div>

              <div className="col-2 mt-3 text-right">
                <Skeleton height={20} width={100} />
              </div>
            </div>
            <Skeleton className="border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
            <Skeleton className="mt-3 border rounded" height={125} />
          </SkeletonTheme>
        </div>
      </div>
    )
  }


}

export default JobTable;