import React from "react";
import TextLoop from "react-text-loop";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import SimpleCustomNavbar from "../components/navbars/SimpleNavbar.js";
import CustomFooter from "../components/footers/Footer.js";
import AddJobSteps from "../components/AddJobSteps.js";

class PostAJob extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const customLength = {
            width: "400px",
        }

        return (
            <div>
                <SimpleCustomNavbar />
                <section className="section mt-5 section-lg d-none d-sm-block">
                    <Container>
                        <Row className="display-3 justify-content-center align-top">
                            <Col className="text-center">
                                <div>
                                    Hire from the largest community of
                                </div>
                                <TextLoop interval={3500} springConfig={{ stiffness: 120, damping: 25 }}>
                                    <span style={customLength}>Consultants</span>
                                    <span style={customLength}>MBB Associates</span>
                                    <span style={customLength}>Investment Bankers</span>
                                    <span style={customLength}>Business Analysts</span>
                                    <span style={customLength}>Big Four Partners</span>
                                </TextLoop>
                            </Col>
                        </Row>
                    </ Container>
                    <div className="mt-15">
                        <AddJobSteps />
                    </div>
                </section>
                <section className="d-sm-none ml-3 mt-5 h2">
                    You can only create jobs on the desktop version of the site.
                </section>
                <CustomFooter />
            </div >
        );
    }
}

export default PostAJob;
