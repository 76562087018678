import React from "react";
import TextLoop from "react-text-loop";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components
import CustomNavbar from "../components/navbars/Navbar.js";
import CustomFooter from "../components/footers/Footer.js";
import HomePageJobs from "../components/HomePageJobs.js";
import SimpleSearchBox from "../components/SimpleSearchBox.js";

class Home extends React.Component {
  render() {
    const customLength = {
      width: "100px",
    };

    return (
      <>
        <CustomNavbar />
        <section className="section mt-5 section-lg ">
          <Container>
            <Row className="display-3 justify-content-center align-top ml-sm-1">
              <Col className="text-left text-sm-center">
                <div>
                  A career after{" "}
                  <TextLoop
                    interval={3500}
                    springConfig={{ stiffness: 120, damping: 25 }}
                  >
                    <span style={customLength}>Consulting</span>
                    <span style={customLength}>Banking</span>
                    <span style={customLength}>M&A</span>
                    <span style={customLength}>Big Four</span>
                    <span style={customLength}>MBB</span>
                  </TextLoop>
                </div>
                <div>
                  <p className="d-sm-none lead">
                    <b>
                      The #1 place to find roles in product, strategy, and
                      venture capital.
                    </b>
                  </p>
                  <p className="d-none d-sm-block lead">
                    <b>
                      The #1 place to find roles in product, strategy, and
                      venture capital.
                    </b>{" "}
                    <br />
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <div className="px-sm-3 d-none d-sm-block">
                  <SimpleSearchBox />
                </div>
                <div className="d-sm-none">
                  <SimpleSearchBox />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <HomePageJobs />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <CustomFooter />
      </>
    );
  }
}

export default Home;
