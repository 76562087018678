import React, { useState, useEffect } from "react";
import PurchaseForm from "./forms/PurchaseForm";
import PaymentService from "../services/PaymentService"
import OrderService from "../services/OrderService"
import { Button } from "reactstrap";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const OrderPayment = ({ currentJob, currentOrder }) => {

    const [order, setOrder] = useState(null);
    const [job, setJob] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setOrder(currentOrder);
        setJob(currentJob);
    }, [])

    if (job && order) {
        return (
            <div className="">
                {order?.paid === false &&
                    <div>
                        <div className="px-5 display-4">
                            Complete Payment
                        </div>
                        <PurchaseForm job={{ ...job, "email": order.customerEmail }} customOrder={order} />
                    </div>
                }            
            </div>
        );
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default OrderPayment;

