import React from "react";

// core components
import CustomNavbar from "../components/navbars/Navbar.js";
import CustomFooter from "../components/footers/Footer.js";
import Job from "../components/Job.js";

class JobPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId: props.match.params.id,            
        };
    }

    render() {
        return (
            <div>
                <CustomNavbar />
                <div className="px-sm-4">
                    <Job id={this.state.jobId} />
                </div>
                <CustomFooter />
            </div>
        );
    }
}

export default JobPage;
