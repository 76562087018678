import React from "react";
import { Link } from "react-router-dom";
import logoImg from '../../assets/img/brand/logo1.svg';

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class CustomNavbar extends React.Component {
  componentDidMount() { }

  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    const logoStyle = {
      width: "140px"
    }

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light fixed"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  style={logoStyle}
                  alt="..."
                  src={logoImg}
                />
                {/* <h1 className="text-theme">
                  ExitHunt
                </h1> */}
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          style={logoStyle}
                          alt="..."
                          src={logoImg}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      href="/search">
                      <span className="cursor-pointer">Advanced Search</span>
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <span className="nav-link-inner--text">Categories</span>
                    </DropdownToggle>
                    <DropdownMenu className="border-1">
                      <DropdownItem onClick={() => { window.location = window.location.origin + "/search?category=Venture%20Capital&pageTitle=Venture%20Capital%20Jobs"; }} tag={Link}>
                        Venture Capital
                      </DropdownItem>
                      <DropdownItem onClick={() => { window.location = window.location.origin + "/search?category=Product&pageTitle=Product%20Jobs"; }} tag={Link}>
                        Product
                      </DropdownItem>
                      <DropdownItem onClick={() => { window.location = window.location.origin + "/search?category=Strategy%20and%20Operations&pageTitle=Strategy/Operations%20Jobs"; }} tag={Link}>
                        Strategy/Operations
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink
                      href="mailto:contact@exithunt.io">
                      <span className="cursor-pointer">Get In Touch</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-icon border-0"
                      color="info"
                      size="large"
                      href="/jobs/new"
                    >
                      <span className="nav-link-inner--text ml-1">
                        <b>Add an opening</b>
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default CustomNavbar;
