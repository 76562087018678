import http from "../http-common";

const get = id => {
    return http.get(`/order/${id}`);
};

const update = (data) => {
    return http.put(`/order`, data);
};

const validatePayment = (data) => {
    return http.post(`/payment/success`, data);
};

const getBillingRedirect = (id) => {
    return http.get(`/billing/${id}`)
}

const createNewOrderOrUpdate = data => {
    return http.post("/order", data);
};

export default {
    get,
    update,
    getBillingRedirect,
    createNewOrderOrUpdate,
    validatePayment
};
