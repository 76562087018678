import React, { useEffect } from "react";
import { Media } from "reactstrap";
import JobListing from './JobListing';

const AddJobPreview = ({ job, handleNextStep, handleBackStep }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const NewTag = {
        float: "right"
    }

    return (
        <div className="mb-5 mt-4">
            <div className="px-5">
                <div className="row">
                    <div className="col">
                        <a
                            className="cursor-pointer text-info ml-4"
                            onClick={handleBackStep}
                        >
                            <span className="nav-link-inner--text">
                                <i className="fa fa-chevron-left mr-2"></i><b>Edit Job Info</b>
                            </span>
                        </a>
                    </div>
                    <div className="col text-right">
                        <a
                            className="cursor-pointer text-info ml-4"
                            onClick={handleNextStep}
                        >
                            <span className="nav-link-inner--text">
                                <b>Next Step</b><i className="fa ml-2 fa-chevron-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div>
                    <div className="text-center px-4 mt-5 mr-2 display-4">
                        Listing Preview
                    </div>
                </div>
                <div className="px-4 my-4">
                    <div className="cursor-pointer border shadow-lg bg-white grow rounded mb-3 item">
                        <div className="py-3 px-3">
                            <div className="row justify-content-between">
                                <div className="col-sm-10 col-12 mt-1 mt-sm-3">
                                    <Media>
                                        {job['logoURL'] &&
                                            <img
                                                alt="..."
                                                className="logo-square border-black border mr-2"
                                                src={job['logoURL']}
                                            />
                                        }
                                        <div>
                                            <div className="mb-1 d-sm-none font-weight-bold text-sm font-bold cut-text">
                                                {job['title']}
                                            </div>
                                            <div className="d-none d-sm-block font-weight-bold text-md font-bold">
                                                {job['title']}
                                            </div>
                                            <div className="text-sm text-gray-500 d-sm-none cut-text-sm">
                                                {job['company']} &middot; {job['location']}
                                            </div>
                                            <div className="text-sm text-gray-500 d-none d-sm-inline">
                                                <span className="mr-1">{job['company']}</span> &middot; <span className="ml-1 mr-1">{job['location']}</span> &middot; <span className="ml-1"> {job['type']} </span>
                                            </div>
                                        </div>
                                    </Media>
                                </div>
                                <div className="d-none d-sm-block col-sm-2 col-0">
                                    <div className="pull-right">
                                        <div style={NewTag} className="text-center py-1 rounded text-xs w-10">
                                            <b>New</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-sm-12 d-none d-sm-block text-xs text-right">
                                    Posted Today
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-5">
                <div className="row">
                    <div className="col">
                        <div className="text-center mr-2 display-4">
                            Page Preview
                        </div>
                    </div>
                </div>
                <JobListing job={{ ...job, "createdAt": new Date() }} linksNotClickable={true} />
            </div>
            <div className="px-5 mt-5">
                <div className="row">
                    <div className="col">
                        <a
                            className="cursor-pointer text-info ml-4"
                            onClick={handleBackStep}
                        >
                            <span className="nav-link-inner--text">
                                <i className="fa fa-chevron-left mr-2"></i><b>Edit Job Info</b>
                            </span>
                        </a>
                    </div>
                    <div className="col text-right">
                        <a
                            className="cursor-pointer text-info ml-4"
                            onClick={handleNextStep}
                        >
                            <span className="nav-link-inner--text">
                                <b>Next Step</b><i className="fa ml-2 fa-chevron-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddJobPreview;