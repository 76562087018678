import React, { useEffect, useState } from "react";

import { v4 as uuidv4 } from 'uuid';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror'
import { Button } from 'reactstrap'
import { options, menu } from '@aeaton/react-prosemirror-config-default'
import { useAlert } from 'react-alert'
import S3 from "react-aws-s3";
import Select from 'react-select';
import store from 'store-js';
import Upload from "./Upload";
import CreatableSelect from 'react-select/creatable';
import JobsDataService from "../../services/JobService"

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function convertArrayToSelectOptionFormat(rawArray) {
    let result = [];

    if (rawArray instanceof Array) {
        rawArray.forEach(element => {
            result.push({ value: element, label: element });
        });
    } else if (rawArray) {
        return { value: rawArray, label: rawArray };
    }
    return result;
}

const JobInfoEditForm = ({ job, updateJob }) => {

    const alert = useAlert();

    const [jobTitle, setJobTitle] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [linkToApply, setLinkToApply] = useState("");
    const [description, setDescription] = useState("");
    const [uploadedLogoUrl, setUploadedLogoUrl] = useState("");
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [locationOptions, setLocationOptions] = useState(null);
    const [locationOptionsOpen, setLocationOptionsOpen] = useState("Select a location");

    useEffect(() => {
        window.scrollTo(0, 0);

        retrieveSelectOptions();

        if (job) {
            setJobTitle(job.title);
            setEmail(job.email);
            setCompanyName(job.company);
            setLinkToApply(job.linkToApplyURL);
            setDescription(job.description);
            setUploadedLogoUrl(job.logoURL);
            setSelectedCategory(convertArrayToSelectOptionFormat(job.category));
            setSelectedLocation(convertArrayToSelectOptionFormat(job.location));
            setSelectedType(convertArrayToSelectOptionFormat(job.type));
        }
    }, [])

    const handleUploadedFile = (file) => {
        if (file) {
            uploadLogo(file)
        }
    }

    const handleSaveJob = () => {
        if (companyName == "") {
            alert.error('Company name missing');
            return;
        }

        if (email == "") {
            alert.error('Email address missing');
            return;
        }

        if (jobTitle == "") {
            alert.error('Job title missing');
            return;
        }

        if (!selectedCategory) {
            alert.error('Job category missing');
            return;
        }

        if (!selectedLocation) {
            alert.error('Job location missing');
            return;
        }

        if (!selectedType) {
            alert.error('Job type missing');
            return;
        }

        if (linkToApply == "") {
            alert.error('Link to apply missing');
            return;
        }

        if (description == "") {
            alert.error('Add a description');
            return;
        }

        updateJob({
            "id": job.id,
            "title": jobTitle,
            "email": email,
            "description": description,
            "company": companyName,
            "logoURL": uploadedLogoUrl,
            "location": selectedLocation['value'],
            "linkToApplyURL": validateLinkToApply(),
            "type": selectedType['value'],
            "category": selectedCategory['value']
        });
    }

    const validateLinkToApply = () => {
        // inspired from https://www.w3resource.com/javascript/form/email-validation.php
        if (EMAIL_REGEX.test(linkToApply)) {
            const emailFormattedLink = `mailto:${linkToApply}`;
            return emailFormattedLink;
        }
        return linkToApply;
    }

    const uploadLogo = (file) => {
        setUploadSpinner(true);
        let newFileName = uuidv4();
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: "http://exithunt.s3.us-east-1.amazonaws.com"
        };
        const ReactS3Client = new S3(config);

        ReactS3Client.uploadFile(file, newFileName).then((data) => {
            if (data.status === 204) {
                setUploadedLogoUrl(data.location);
                setUploadSpinner(false);
            } else {
                console.log("failed");
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const handleCategoryChange = (change) => {
        setSelectedCategory(change);
    }

    const handleLocationChange = (change) => {
        setSelectedLocation(change);
    }

    const handleTypeChange = (change) => {
        setSelectedType(change);
    }

    const customSelectStyles = {
        control: base => ({
            ...base,
            height: 60,
            minHeight: 60
        })
    };

    const editorHeight = {
        height: "300px"
    }

    const retrieveSelectOptions = () => {
        const selectOptions = store.get('selectOptions');

        if (selectOptions) {
            setLocationOptions(convertArrayToSelectOptionFormat(selectOptions['locations']));
        } else {
            JobsDataService.getSelectOptions()
                .then((response) => {
                    store.set('selectOptions', response)
                    setLocationOptions(convertArrayToSelectOptionFormat(response['locations']));
                })
        }
    }

    const categoryOptions = [
        { value: 'Venture Capital', label: 'Venture Capital' },
        { value: 'Product', label: 'Product' },
        { value: 'Strategy and Operations', label: 'Strategy and Operations' }
    ]

    const typeOptions = [
        { value: 'Full-Time', label: 'Full-Time' },
        { value: 'Contract', label: 'Contract' },
        { value: 'Part-Time', label: 'Part-Time' },
        { value: 'Internship', label: 'Internship' },
    ]

    return (
        <div className="mb-5">
            <div className="border shadow-lg rounded py-4">
                <div className="display-4 px-4">
                    Company
                </div>
                <div className="mt-5 px-4 text-left">
                    <div className="row">
                        <div className="col">
                            <div className="h3 font-weight-bold text-info">
                                <b>Company name</b>
                            </div>
                            <div className="w-full mt-3">
                                <input className="form-control text-dark w-full pl-2 py-4 border-1 rounded pr-2" type="text" onChange={e => setCompanyName(e.target.value)} value={companyName} />
                            </div>
                        </div>

                        <div className="col">
                            <div className="h3 text-info">
                                <b>Email address</b>
                            </div>
                            <div className="w-full mt-3">
                                <input className="form-control text-dark w-full pl-2 py-4 border-1 rounded pr-2" type="text" onChange={e => setEmail(e.target.value)} value={email} />
                            </div>
                            <div className="mt-1 text-sm text-gray">Receipts and confirmation emails are sent here.</div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 mb-2 px-4">
                    <div className="col">
                        <div className="h3 text-info">
                            <b>Company Logo</b>
                        </div>
                        <div className="mt-3">
                            <Upload loader={uploadSpinner} uploadedFileHandler={handleUploadedFile} />
                        </div>
                    </div>
                    {uploadedLogoUrl &&
                        <div className="col-3">

                            <div className="text-center mt-5">
                                <img
                                    alt="..."
                                    className="logo-square-lg border-black border mx-auto"
                                    src={uploadedLogoUrl}
                                />
                                <div className="mt-1">
                                    Logo Preview
                            </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="text-center mt-3">
                <Button
                    className="btn-icon border-0"
                    color="info"
                    size="lg"
                    onClick={handleSaveJob}
                >
                    <span className="nav-link-inner--text">
                        <b>Save Edits</b>
                    </span>
                </Button>
            </div>
            <div className="border shadow-lg rounded mt-5 py-4">
                <div className="display-4 px-4">
                    Job Listing
                </div>
                <div className="mt-5 px-4 text-left">
                    <div className="row">
                        <div className="col">
                            <div className="h3 font-weight-bold text-info">
                                <b>Job Title</b>
                            </div>
                            <div className="w-full mt-3">
                                <input className="form-control text-dark w-full pl-2 py-4 border-1 rounded pr-2" type="text" onChange={e => setJobTitle(e.target.value)} value={jobTitle} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-4">
                            <div className="h3 font-weight-bold text-info">
                                <b>Job Category</b>
                            </div>
                            <Select
                                placeholder="Select a category"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                options={categoryOptions}
                                className="text-sm shadow-sm mt-3"
                                pageSize={6}
                                styles={customSelectStyles}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#FFFFFF',
                                        primary: '#ADB5BD',
                                        neutral20: '#CAD1D7'
                                    },
                                })}
                            />
                        </div>
                        <div className="col-4">
                            <div className="h3 font-weight-bold text-info">
                                <b>Location</b>
                            </div>
                            <CreatableSelect
                                isClearable
                                placeholder={locationOptionsOpen}
                                onFocus={() => { setLocationOptionsOpen("...or add a new one") }}
                                onBlur={() => { setLocationOptionsOpen("Select a location") }}
                                value={selectedLocation}
                                onChange={handleLocationChange}
                                options={locationOptions}
                                styles={customSelectStyles}
                                className="text-sm shadow-sm mt-3"
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#FFFFFF',
                                        primary: '#ADB5BD',
                                        neutral20: '#CAD1D7'
                                    },
                                })}
                            />
                        </div>
                        <div className="col-4">
                            <div className="h3 font-weight-bold text-info">
                                <b>Job Type</b>
                            </div>
                            <CreatableSelect
                                isClearable
                                placeholder="Select type"
                                value={selectedType}
                                onChange={handleTypeChange}
                                options={typeOptions}
                                styles={customSelectStyles}
                                className="text-sm shadow-sm mt-3"
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#FFFFFF',
                                        primary: '#ADB5BD',
                                        neutral20: '#CAD1D7'
                                    },
                                })}
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <div className="h3 font-weight-bold text-info">
                                <b>Link to Apply (or an email address)</b>
                            </div>
                            <div className="w-full mt-3">
                                <input className="form-control text-dark w-full pl-2 py-4 border-1 rounded pr-2" type="text" onChange={e => setLinkToApply(e.target.value)} value={linkToApply} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 mb-2">
                        <div className="col">
                            <div className="h3 font-weight-bold text-info">
                                <b>Job Description</b>
                            </div>
                            <HtmlEditor
                                options={options}
                                value={job?.description || description}
                                onChange={(value) => { setDescription(value); }}
                                render={({ editor, view }) => (
                                    <div className="mt-3 border border rounded">
                                        <div className="px-1 py-1">
                                            <MenuBar menu={menu} view={view} />
                                            <div style={editorHeight} className="overflow-auto">
                                                {editor}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-3">
                <Button
                    className="btn-icon border-0"
                    color="info"
                    size="lg"
                    onClick={handleSaveJob}
                >
                    <span className="nav-link-inner--text">
                        <b>Save Edits</b>
                    </span>
                </Button>
            </div>
        </div>
    );
}

export default JobInfoEditForm;