/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class CustomFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footerBottom footer">
          <Container>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright d-none d-sm-inline">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="/"
                  >
                    Exithunt.io
                  </a>
                  .

                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-sm-end justify-content-center">
                  <NavItem>
                    <NavLink
                      className="text-left"
                      href="mailto:contact@exithunt.io"
                    >
                      Get in Touch
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/jobs/new"
                    >
                      Post a Job
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.termsfeed.com/live/46e495df-8eb4-4663-9561-1090423412c5"
                    >
                      Terms of Service
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <div className="copyright d-sm-none text-center mt-4">
              © {new Date().getFullYear()}{" "}
              <a
                href="/"
              >
                Exithunt.io
                  </a>
                  .

                </div>
          </Container>
        </footer>
      </>
    );
  }
}

export default CustomFooter;
